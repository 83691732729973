import { createGlobalStyle, css } from 'styled-components'

import 'normalize.css'
import 'system-font-css'
import 'assets/fonts/fontface.css'


const globalStyles = css`
#root{
  display:flex;
  flex:1;
}

  html, body, .viewport {
        width: 100%;
        height: 100%;
        margin: 0;
        display:flex;
        /* max-height: 100%;
        max-width:100%; */
      }
  html {
    box-sizing: border-box;
    font-size: calc(12px + .25vw);
  }
  body {
    font-family: liebling, system-ui, sans-serif;
    color: var(--text);
    .MuiTypography-root,
    .MuiOutlinedInput-input,
    .MuiInputBase-root,
    .MuiButtonBase-root,
    .MuiButton-label,
    .MuiFormLabel-root,
    .MuiTooltip-tooltip,
    .MuiTableCell-root,
    p, text {
      font-family: liebling, system-ui, sans-serif;
    }
  }
  *, *:before, *:after {
    box-sizing: inherit;
    outline: none;
  }
  a {
    text-decoration: none;
    color: #2460c3;
    &:hover {
      border-bottom: 1px solid #4373c3;
    }
  }
  :root {
    --dark-back: #272726;
    --back: #E2E5E9;
    --button: #0279de;
    --input-back: #e8e8e8;
    --red: #ff6666;
    --green: #6dca75;
    --blue: #0a85ff;
    --black: #212121;
    --white: #fff;
    --gray: #e0e0e0;
    --light-gray: #c8c8c8;
    --text: #272727;
    --sub-text: #27272780;
    --page: rgba(237,237,237,0.12);
    --bdrs: 18px;
  }
  .MuiListItem-root {
    body[dir=rtl] & {
      text-align: right;
    }
  }
  
  .MuiButton-startIcon {
    body[dir=rtl] & {
      margin-left: 8px;
      margin-right: -4px;
    }
  }
  
  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    body[dir=rtl] & {
      transform: translate(-14px, -4px) scale(0.75);
      right: 0;
      left: unset;
      transform-origin: top right;
    }
  }
  .MuiInputLabel-formControl {
    body[dir=rtl] & {
      right: 0;
      transform: translate(-14px, 20px) scale(1);
    }
  }
  .PrivateNotchedOutline-legendLabelled-3 {
    body[dir=rtl] & {
      text-align: right;
    }
  }
  .MuiOutlinedInput-root {
    legend {
      text-align: unset;
    }
  }
  
  .MuiOutlinedInput-inputAdornedEnd {
    body[dir=rtl] & {
      padding-left: 0;
      padding-right: 14px;
    }
  }
  .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    body[dir=rtl] & {
    padding-right: 9px;
    padding-left: 65px;
    }
  }

  .MuiOutlinedInput-adornedEnd {
    body[dir=rtl] & {
      padding-left: 14px;
      padding-right: 0;
    }
  }
  
  
  .MuiOutlinedInput-inputAdornedStart {
    padding-left: 6px;
    padding-right: 14px;
    body[dir=rtl] & {
      padding-left: 14px;
      padding-right: 6px;
    }
  }
  .MuiOutlinedInput-adornedStart{
    padding-left: 14px;
    padding-right: 6px;
    body[dir=rtl] & {
      padding-left: 6px;
      padding-right: 14px;
    }
  }
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-endAdornment {
    body[dir=rtl] & {
    right: unset;
    left: 9px;
    }
  }
 
  .MuiAutocomplete-popupIndicator {
    body[dir=rtl] & {
    padding: 2px;
    margin-right: unset;
    margin-left: -2px;
    }
  }

    .MuiTypography-root {
    max-width: 80vw !important;
  }

  .MuiTableCell-root {
    body[dir=rtl] & {
      text-align:center;
    }
  }

  .MuiTooltip-tooltip{
    background-color: #3a3a3a;
    .MuiTooltip-arrow {
      color: #3a3a3a;
    }
  }

  .MuiFormControlLabel-root {
    body[dir=rtl] & {
      margin-right: -11px;
      margin-left: 16px;
    }
  }

  .MuiButton-endIcon {
    body[dir=rtl] & {
      margin-right: 8px;
      margin-left: -4px;
    }
  }
// rich text editor css do not remove
  .drop-wrap{
    left:unset !important;
  }
  .Progress_bar {
  background-color: lightgray;
}

.budgetBreak {
    width: 90%;
    align-self:center;
    text-align: center;
    border-bottom: 1px solid #000;
    line-height: 0.1em;
    margin: 10px 0 20px;
}
.budgetBreak span {
    background:var(--back);
    padding: 0 10px;
}
.frame {
 flex: 1 1 auto;
 border: 0;
}
.MuiPickersBasePicker-pickerView{
  background-color:#007eff;
  color: #fff;
}
.MuiPickersDay-day{
  color:#fff;
  &.MuiPickersDay-dayDisabled {
    color: rgba(255, 255, 255, 0.5);
  }
}
.MuiPickersStaticWrapper-staticWrapperRoot{
  border-radius:1rem;
}
.MuiPickersDay-daySelected,.MuiPickersDay-daySelected:hover{
    color:#007eff;
    font-weight: 500;
    background-color: #fff;
}
.MuiIconButton-root:hover {
    background-color: rgb(255 255 255 / 50%);
}
.MuiPickersCalendarHeader-iconButton{
  background-color:inherit;
  color: #fff;
}
.MuiPickersToolbar-toolbar{
  direction:ltr;
}
.MuiPickersClockNumber-clockNumber{
  color:white;
}
.Mui-error{
  body[dir=rtl] & {
      text-align:right;
    }
}
// all classes that start with "SnackbarItem-action"
[class^="SnackbarItem-action"]{
  body[dir=rtl] & {
      padding-left:0px;
      padding-right:16px;
      margin-right:0px;
      margin-left:-8px;
      margin-right:auto;
    }
}
/* .MuiPickersDay-dayDisabled {
    color: rgba(255, 255, 255, 0.5);
} */
`

const GlobalStyles = createGlobalStyle`${globalStyles}`

export default GlobalStyles

