export const storageKeys = {
  currentCommunityId: '@mekome_current_community_id',
  currentGroupId: '@mekome_current_group_id',
  currentTab: '@mekome_current_tab',
  LANG: '@MEKOME_ADMIN_LANG',
  tokenCommunity: '@mekome_token_community',//if user managed to login iframe with token
  token: '@mekome_token'
}

const veryLocalStorage = {
  set(key: string, value: any): void {
    const toSave = JSON.stringify(value)
    localStorage.setItem(key, toSave)
  },
  get(key: string): any {
    try {
      const data = localStorage.getItem(key)
      if (!data) return false
      const json = JSON.parse(data)
      return json
    } catch {
      return false
    }
  },
  remove(key: string): void {
    localStorage.removeItem(key)
  },
  clear(): void {
    Object.values(storageKeys).forEach(key => veryLocalStorage.remove(key))
  }
}

export default veryLocalStorage
