import ReactDOM from 'react-dom'
import App from './core/App'
import firebase from "firebase/app"
import "firebase/analytics"
import { parse, format, addHours } from 'date-fns'
import { isTotzeret } from 'service/whiteLable'

// Use your config values here.
try {

  firebase.initializeApp({
    apiKey: "AIzaSyAQT_cb0iRVbPK-lmHLJaZgqwaFaJJGxAc",
    authDomain: "soy-zone-750.firebaseapp.com",
    databaseURL: "https://soy-zone-750.firebaseio.com",
    projectId: "soy-zone-750",
    storageBucket: "soy-zone-750.appspot.com",
    messagingSenderId: "464930886928",
    appId: "1:464930886928:web:a348cb93f215f2f4f7c195",
    measurementId: "G-3X7VNQ762T"
  });
  firebase.analytics();
}
catch (e) {
  console.log("firebase.initializeApp error", e)
}

if (location.host !== 'web.mekome.net' && !isTotzeret) {
  try {
    const ts = parse(process.env.REACT_APP_TS, 'dd/MM HH:mm:ss', new Date())
    const t = format(addHours(ts, new Date().getTimezoneOffset() / -60), "dd/MM 'at' HH:mm:ss")
    document.title = `Deployed: ${t}`
  } catch (e) { }
}




ReactDOM.render(<App />, document.getElementById('root'))
