import { observable, action, computed } from 'mobx'
import api from 'service/api'
import ls, { clear } from 'local-storage'
import type { SendApiUserReq } from 'utils/types'
import type { User, AppType } from 'utils/models'
import i18next from 'i18next'
import { enUS, he } from 'date-fns/locale'
import { storageKeys } from 'utils/vls'


const USER = '@mekome_admin_user'
const IS_AUTH = '@mekome_user_is_auth'

class UserStore {

  constructor() {
    this.restore()
  }

  restore = () => {
    const user: User | undefined = ls(USER) as (User | undefined)
    if (user) this.login(user)
    const isAuth = ls(IS_AUTH) as (boolean | undefined)
    if (isAuth) this.setIsAuth(isAuth)
  }

  tempToken = ''

  @observable phone: string = ''

  @observable email: string = ''

  @observable jwtToken: string = ''

  @observable user: User // TODO: add | null

  @observable isAuth = false

  @observable communitiesNames: string[] = []

  @computed get hasToken() { return !!this.user?.user_token }

  @computed get credentials() { return { id: this.user?.user_id, token: this.user?.user_token } }

  @computed get token() { return this.user?.user_token }

  @computed get name() { return `${this.user.given_name} ${this.user.family_name}` }

  @computed get dateFnsLocal() { return i18next.language === 'he' ? he : enUS }

  @observable userProfileOpen: boolean = false

  @observable isUserListToRemoveModalOpen = false

  @action openUserProfile = () => {
    this.userProfileOpen = true
  }
  @action closeUserProfile = () => {
    this.userProfileOpen = false
  }
  @action toggleUserProfile = () => {
    this.userProfileOpen = !this.userProfileOpen
  }
  @action setPhone = async (phone: string) => {
    this.phone = phone
  }
  @action setEmail = async (email: string) => {
    this.email = email
  }
  @action setUser = async (user: User) => {
    this.login(user)
  }

  @action setShowUserListToRemoveModal = (bool: boolean) => {
    this.isUserListToRemoveModalOpen = bool
  }

  @action
  setIsAuth = (state: boolean) => {
    ls(IS_AUTH, state)
    this.isAuth = state
  }

  @action
  setJwt(token: string) {
    ls(storageKeys.token, token)
    this.jwtToken = token;
  }

  @action
  setTempToken(token: string) {
    this.tempToken = token;
  }

  sendVerificationCode = async (code: string) => {
    
    const { data, success } = await api.checkCode(code, this.tempToken);
    if (success === true) {
      const { user: serverUser, token } = data;
      let locUser: User = {
        emails: serverUser.emails,
        family_name: serverUser.familyName,
        given_name: serverUser.givenName,
        nick_name: serverUser.nickName,
        phones: serverUser.phones,
        user_token: token,
        user_id: serverUser.userId,
        eulaSigned: serverUser.eulaSigned,
        role: serverUser.role
      }
      this.communitiesNames = (serverUser.communities || []).map((com: { id: string; name: string }) => com.name)
      this.setUser(locUser)
      return ''
    }
    else {
      return user.error || ''
    }

  }

  @action login = (user: User) => {
    ls(USER, user)
    this.setJwt(user.user_token || '')
    this.user = user
  }

  sendPhoneOrEmail = async (input: string) => {
    const res = await api.getCode(input)
    if (res.success) {
      if (input.indexOf('@') == -1) {
        this.setPhone(input)
        this.setEmail('')
      } else {
        this.setEmail(input)
        this.setPhone('')
      }
      this.setTempToken(res.data);
      return ''
    } else {
      return res.error || ''
    }
  }

  @action
  cleenData() {
    clear()
    location.reload()
  }

  @action
  logout = async () => {
    await api.logout();
    this.cleenData();
  }

  @action getAsApiUserReq(givenUser: User) {
    let user: SendApiUserReq = {
      id: 1,
      user: givenUser.user_id,
      given: givenUser.given_name,
      family: givenUser.family_name,
      nick: givenUser.nick_name,
      phones: givenUser.phones,
      emails: givenUser.emails
    }
    return user
  }
  @action signEula() {
    this.user.eulaSigned = true
    api.signEula()
  }
}

const user = new UserStore()
export default user
